import React,{useContext,useEffect,useState} from 'react'
import './checkout.css'
import {useSelector,useDispatch} from 'react-redux'
import {useLocation,useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { HeaderContext } from '../../context/HeaderContext';
import {getAllCoupons} from '../../features/coupon/couponSlice'
import {createAbondend,createAnOrder,resetState,sendOtp} from '../../features/user/userSlice'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';


const Checkout = () => {
  const {headTextSet,removeFromCart}=useContext(HeaderContext)
  useEffect(()=>{
headTextSet("Checkout")
  },[])

  const otpState=useSelector((state)=>state?.auth?.otp)
  const [firstname,setFirstname]=useState("")
  const [lastname,setLastname]=useState("")
  const [success,setSuccess]=useState(false)

  const [email,setEmail]=useState("")

  const [phone,setPhone]=useState("")
  const [mobile,setMobile]=useState("")
  const [address,setAddress]=useState("")
  const [city,setCity]=useState("")
  const [state,setState]=useState("State")
  const [pincode,setPincode]=useState("")
  const [verified,setVerified]=useState(false)

  const [cartItems, setCartItems] = useState([]);
const [ship,setShip]=useState({})
const address1=JSON.parse(localStorage.getItem("address"))
useEffect(()=>{
  if (!firstname && !lastname && !email && !address && !phone && !mobile && !city && !state && !pincode) {
      // Populate fields only if they're empty
      setFirstname(address1?.firstname || "")
      setLastname(address1?.lastname || "")
      setEmail(address1?.email || "")
      setAddress(address1?.address || "")
      setPhone(address1?.phone || "")
      setMobile(address1?.mobile || "")
      setCity(address1?.city || "")
      setState(address1?.state || "")
      setPincode(address1?.pincode || "")
  }
},[address1, firstname, lastname, email, address, phone, mobile, city, state, pincode])
  const location = useLocation();
  const navigate=useNavigate()

  const addProductToOrderLocalStorage = (product) => {
      const existingOrder = JSON.parse(localStorage.getItem("orders")) || [];
      const updatedOrder = [...existingOrder, product];
      localStorage.setItem("orders", JSON.stringify(updatedOrder));
    };
    function normalizePhoneNumber(phoneNumber) {
      // Remove all non-digit characters from the phone number
      let cleanNumber = phoneNumber.replace(/\D/g, '');
  
      // Check if the number starts with '91' (India's country code) and is longer than 10 digits
      if (cleanNumber.startsWith('91') && cleanNumber.length > 10) {
          // Remove the '91' prefix
          cleanNumber = cleanNumber.substring(2);
      } else if (cleanNumber.startsWith('0') && cleanNumber.length > 10) {
          // Remove the leading '0' if any (common in some domestic formats)
          cleanNumber = cleanNumber.substring(1);
      }
  
      // Return the cleaned up number assuming it should be 10 digits long
      return cleanNumber;
  }

  useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/checkout`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
    const [verify,setVerify]=useState("SEND OTP")
    const [otp,setOtp]=useState()
    const [noneOtp,setNoneotp]=useState("none")
    const [paySpin,setPaySpin]=useState(false)
  const [totalAmount,setTotalAmount]=useState(null)
  const [orderType,setOrderType]=useState("Prepaid")
  const [shippingCost,setShippingCost]=useState(0)
  const [discount,setDiscount]=useState(0)
  const [cartProductState,setCartProductState]=useState([])
  const [coupon,setCoupon]=useState("")
  const [couponAmount,setCouponAmount]=useState(0)
  const couponState=useSelector((state)=>state?.coupon?.coupon)
  const [payMethod,setPayMethod]=useState("razorpay")
  useEffect(() => {
      // Retrieve cart items from localStorage
      const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(cartFromStorage);

  }, []);
  useEffect(()=>{
      setCouponAmount((totalAmount)/20)
  },[totalAmount])

  const removeFromCartAndUpdate = (productIdToRemove, colorToRemove, sizeToRemove) => {
      // Filter out the item to remove based on productId, color, and size
      const updatedCartItems = cartItems.filter(item => {
          return !(item.productId === productIdToRemove && item.color === colorToRemove && item.size === sizeToRemove);
      });

      // Update localStorage with the updated cart items
      localStorage.setItem('cart', JSON.stringify(updatedCartItems));

      // Update state to reflect the changes
      setCartItems(updatedCartItems);
      removeFromCart(updatedCartItems)
      toast.success("Removed");
  };
  const applyCoupon=()=>{
      couponState?.map((item)=>{
          if((item?.name.toLowerCase())===(coupon.toLowerCase())){
              if(item?.status==="active"){
                  if(item?.discounttype==="freeShip"){
                      setShippingCost(0)
                  }
                  if(item?.discounttype==="buyX"){
                      if(item?.minItem<=10){
                        if(item?.minItem>=cartItems?.length){
                          if(item?.discount?.includes("%")){
                              const percent=parseFloat(item?.discount)/100
                              setCouponAmount(percent*totalAmount)
                          }
                          else{
                              setCouponAmount(parseInt(item?.discount))
                          }
                        }
                        else{
                          toast.error("")
                        }
                      }
                      else{
                              if(item?.discount?.includes("%")){
                                  const percent=parseFloat(item?.discount)/100
                                  setCouponAmount(percent*totalAmount)
                              }
                              else{
                                  setCouponAmount(parseInt(item?.discount))
                              }
                            
                      }
                  }
                  if(item?.discounttype==="order"){
                      if(item?.discount?.includes("%")){
                          const percent=parseFloat(item?.discount)/100
                          setCouponAmount(percent*totalAmount)
                      }
                      else{
                          setCouponAmount(parseInt(item?.discount))
                      }
                  }
                  toast.success("Coupon Code Applied")
              }
          }

      })
  }
  const codClick=()=>{
      setShippingCost(200)
      setOrderType("COD")
      setCouponAmount(0)
      setPayMethod("cod")

  }

  const phonepeClick=()=>{
      setShippingCost(0)
      setOrderType("Prepaid")
      setCouponAmount((totalAmount)/5)
      setPayMethod("razorpay")
  }

  const finalAmount=shippingCost+totalAmount-couponAmount
  const dispatch=useDispatch();
  useEffect (()=> {
      let sum=0;
      for(let index=0; index < cartItems?.length; index++){
          sum =sum+(Number(cartItems[index]?.quantity) *cartItems[index]?.price)
          setTotalAmount(sum)
      }
  },[cartItems])
  useEffect(()=>{
      dispatch(getAllCoupons())

  },[])

const completeOrder=()=>{
  if(firstname==="" || lastname==="" || email==="" || phone==="" || mobile==="" || address==="" || city==="" || state==="" || pincode===""){
      toast.info("Please Fill All Information")
  }
  else if(verified===false){
      toast.error("Please Verify First")
  }
  else{
          setPaySpin(true)
         localStorage.setItem("address",JSON.stringify({
          firstname:firstname,
          lastname:lastname,
          email:email,
          address:address,
          phone:normalizePhoneNumber(phone),
          mobile:mobile,
          city:city,
          state:state,
          pincode:pincode,
          isVarified:verified
         }))
         if(cartItems?.length>=1){
          setSuccess(true)
          setTimeout(()=>{
              checkOutHandler()
          },300)
         }
         const addr = JSON.parse(localStorage.getItem("temp"));
          if (addr?.orderItems?.length > 0) {
                  if (addr?.shippingInfo?.firstname !== "" && addr?.shippingInfo?.phone !== "" && addr?.success===false) {
                      dispatch(createAbondend(addr));
                  
              }
          }
         
      }
  }



const loadScript=(src)=>{
  return new Promise((resolve)=>{
      const script=document.createElement("script")
      script.src=src
      script.onload=()=>{
          resolve(true)
      }
      script.onerror=()=>{
          resolve(false)
      }
      document.body.appendChild(script)
  })
}

useEffect(()=>{
  let items=[]
  for (let index = 0; index < cartItems?.length; index++) {
      items.push({product:cartItems[index]?.product,quantity:cartItems[index].quantity,price:cartItems[index].price,color:cartItems[index].color,size:cartItems[index].size})
      
  }
  setCartProductState(items)
},[cartItems])
const [imageIndex, setImageIndex] = useState(0);

const handleImageError = () => {
// Increment the image index to load the next image URL
setImageIndex(prevIndex => prevIndex + 1);
};
const checkOutHandler=async(e)=>{
  if (orderType === "COD") {
      // If order type is COD, proceed with placing the order without opening Razorpay
      const data = {
          orderCreationId: "COD", // Set a placeholder value for order creation ID for COD orders
          razorpayPaymentId: "COD", // Set a placeholder value for Razorpay payment ID for COD orders
          razorpayOrderId: "COD", // Set a placeholder value for Razorpay order ID for COD orders
      };

      // Simulating a successful payment verification for COD orders
      await dispatch(createAnOrder({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address"))}))
  addProductToOrderLocalStorage({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")) })
localStorage.removeItem('cart');
      navigate("/thankyou")
      setPaySpin(false)
      dispatch(resetState())
  }
  else{
      
     if(payMethod==="razorpay"){
     checkOutHandler1()

     }
   }
 
  

}
const checkOutHandler1=async()=>{
  const res=await loadScript("https://checkout.razorpay.com/v1/checkout.js")
  if(!res){
     alert("Razorpay SDK failed to load")
     return
  }
  const result=await axios.post("https://lybrra.onrender.com/api/user/order/checkout1",{amount:parseInt(finalAmount)})
  if(!result){
     alert("Something went wrong")
     return
  }
 
  const {amount,id:order_id,currency}=result.data.order
  const options = {
     key: "rzp_live_CDyasQlE6GSv1I", // Enter the Key ID generated from the Dashboard
     amount: amount,
     currency: currency,
     name: "Lybrraa",
     description: "Lybrraa Payment",
     image:"https://res.cloudinary.com/dtifzt5oe/image/upload/v1725091537/u5f5likvzkphypymdtvc.png",
     order_id: order_id,
     handler: async function (response) {
         const data = {
             orderCreationId: order_id,
             razorpayPaymentId: response.razorpay_payment_id,
             razorpayOrderId: response.razorpay_order_id,
 
         };
 
         const result = await axios.post("https://lybrra.onrender.com/api/user/order/paymentVerification1", data);
         await dispatch(createAnOrder({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address"))}))
         addProductToOrderLocalStorage({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address"))})
         localStorage.removeItem('cart');
         navigate("/thankyou")
  setPaySpin(false)
     dispatch(resetState())
 
     },
     prefill: {
         name: "Lybrraa",
         email: "Lybrraa@gmail.com",
         contact: "8920744425",
     },
     notes: {
         address: "Lybrraa",
     },
     theme: {
         color: "#61dafb",
     },
 };
 
 const paymentObject = new window.Razorpay(options);
 paymentObject.open();
 }

useEffect(()=>{
  localStorage.setItem("temp",JSON.stringify(
      {
          shippingInfo:{
      firstname:firstname,
      lastname:lastname,
      email:email,
      phone:normalizePhoneNumber(phone),
      address:address,
      city:city,
      state:state,
      pincode:pincode,
      mobile:mobile,},
              orderItems:cartProductState,
              totalPrice:totalAmount,
              shippingCost:shippingCost,
              orderType:orderType,
              discount:couponAmount,
              finalAmount:finalAmount,
              success:success
  }))
},[firstname,lastname,email,phone,mobile,address,city,state,pincode,cartProductState,success])

const [isRead,setIsread]=useState(false)
const initialTime = 120;

// State to keep track of the remaining time
const [timeLeft, setTimeLeft] = useState(initialTime);

  // Only set the interval if timeLeft is greater than 0
 

  const [intervalId, setIntervalId] = useState(null);

  
const sendOtps=async()=>{
  if(phone?.length<10){
      toast.info("Please Fill Correct Number")
  }
  else{
      setVerify("Verify")
  setNoneotp("block")
  await dispatch(sendOtp(normalizePhoneNumber(phone)))
  setTimeLeft(initialTime); // Reset the countdown timer

  // Clear any existing interval (safety check)
  if (intervalId) clearInterval(intervalId);

  // Start a new countdown timer
  const id = setInterval(() => {
      setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
              clearInterval(id); // Stop the countdown timer when it reaches zero
              return 0;
          }
          return prevTime - 1;
      });
  }, 1000);
  setIntervalId(id);
  }

}

const verifyOtp=()=>{
  console.log(otpState,otp)

if(otpState?.otps===parseInt(otp)){
  setVerified(true)
  toast.success("VERIFIED")
  setIsread(true)
  

}
else{
  setVerified(false)
  setIsread(true)
  toast.error("Wrong OTP")
}
}
useEffect(() => {
  // Cleanup interval on component unmount
  return () => {
      if (intervalId) clearInterval(intervalId);
  };
}, [intervalId]);
const formatTime = () => {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};



  return (
    <div className='checkout'>
      <div className="left">
<div className="form">
<div className="email">
  <p>Contact</p>
  <TextField
          id="outlined-required"
          label="Email"
          type='email'
          className='input'
          value={email} onChange={(e)=>setEmail(e.target.value)} 
        />
</div>
<div className="delivery">
  <p>Delivery</p>
  <div className="name">
  <TextField
          id="outlined-required"
          label="Firstname"
          type='text'
          className='input'
          value={firstname} onChange={(e)=>setFirstname(e.target.value)}
        />
          <TextField
          id="outlined-required"
          label="Lastname"
          type='text'
          className='input'
          value={lastname} onChange={(e)=>setLastname(e.target.value)}

        />
  </div>
  <div className="phone">
  <TextField
          id="outlined-required"
          label="Phone Number"
          type='number'
          className='input'
          value={phone} onChange={(e)=>setPhone(e.target.value)}


        />
  </div>
  <div className="address">
  <TextField
          id="outlined-required"
          label="Address"
          type='text'
          className='input'
          value={address} onChange={(e)=>setAddress(e.target.value)}


        />
  </div>
  <div className="city">
  <TextField
          id="outlined-required"
          label="City"
          type='text'
          className='input'
          value={city} onChange={(e)=>setCity(e.target.value)}


        />
  </div>
  <div className="state">
  
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="State"
    className='input'
    value={state} onChange={(e)=>setState(e.target.value)}
  >
<MenuItem value="State">State</MenuItem>
    <MenuItem value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</MenuItem>
<MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
<MenuItem value="Arunachal Pradesh">Arunachal Pradesh</MenuItem>
<MenuItem value="Assam">Assam</MenuItem>
<MenuItem value="Bihar">Bihar</MenuItem>
<MenuItem value="Chandigarh">Chandigarh</MenuItem>
<MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
<MenuItem value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</MenuItem>
<MenuItem value="Daman and Diu">Daman and Diu</MenuItem>
<MenuItem value="Delhi">Delhi</MenuItem>
<MenuItem value="Goa">Goa</MenuItem>
<MenuItem value="Gujarat">Gujarat</MenuItem>
<MenuItem value="Haryana">Haryana</MenuItem>
<MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
<MenuItem value="Jammu and Kashmir">Jammu and Kashmir</MenuItem>
<MenuItem value="Jharkhand">Jharkhand</MenuItem>
<MenuItem value="Karnataka">Karnataka</MenuItem>
<MenuItem value="Kerala">Kerala</MenuItem>
<MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
<MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
<MenuItem value="Maharashtra">Maharashtra</MenuItem>
<MenuItem value="Manipur">Manipur</MenuItem>
<MenuItem value="Meghalaya">Meghalaya</MenuItem>
<MenuItem value="Mizoram">Mizoram</MenuItem>
<MenuItem value="Nagaland">Nagaland</MenuItem>
<MenuItem value="Orissa">Orissa</MenuItem>
<MenuItem value="Pondicherry">Pondicherry</MenuItem>
<MenuItem value="Punjab">Punjab</MenuItem>
<MenuItem value="Rajasthan">Rajasthan</MenuItem>
<MenuItem value="Sikkim">Sikkim</MenuItem>
<MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
<MenuItem value="Telangana">Telangana</MenuItem>
<MenuItem value="Tripura">Tripura</MenuItem>
<MenuItem value="Uttaranchal">Uttaranchal</MenuItem>
<MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
<MenuItem value="West Bengal">West Bengal</MenuItem>
  </Select>
          <TextField
          id="outlined-required"
          label="ZIP Code"
          type='number'
          className='input'
          value={pincode} onChange={(e)=>setPincode(e.target.value)}


        />
  </div>
</div>
</div>
<div className="payment">
<p>Payment Method</p>

<div className="note">
<p>You are just Rs. {2000 - finalAmount} away to get Free Delivery</p>
</div>
<div className="option">
<FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="razorpay"
        name="radio-buttons-group"
      >
        <FormControlLabel value="razorpay" control={<Radio />} label="Razorpay Secure Payments" onClick={phonepeClick}/>
        <FormControlLabel value="cod" control={<Radio />} label="Cash on Delivery" onClick={codClick}/>
      </RadioGroup>
    </FormControl>

</div>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}} className='pay'>
                        {
                            paySpin===true?<CircularProgress />:
                            <button disabled={paySpin} onClick={completeOrder}>Complete</button>

                        }
                    </div>
      </div>
      <div className="right">
<div className="check-prdts">
  {
    cartItems?.map((item,index)=>{
      return <div className="item">
      <div className="left-item">
        <div className="img">
        <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" />
        <p className="qty">{item?.quantity}</p>
        </div>
        <div className="content">
          <p className="name">{item?.product?.title}</p>
          <p className="color"><span>Colour:</span><span>{item?.color}</span></p>
          <p className="color"><span>Size:</span><span>{item?.size}</span></p>

        </div>
      </div>
      <div className="right-item">
          <p className="price">Rs. {item?.price}</p>
          <p className="delete" onClick={() => removeFromCartAndUpdate(item.productId, item.color, item.size)}><DeleteIcon className='delete-icon'/></p>
      </div>
    </div>
    })
  }
  

</div>
<div className="coupon">
  <input id="coupon"
                            label="Coupon Code"
                            type="text"
                            value={coupon}
                            onChange={(e)=>setCoupon(e.target.value)}/>
  <button onClick={applyCoupon}>APPLY</button>
</div>
<div className="total">
  <div className="total-left">
<ul>
  <li>Subtotal</li>
  <li>Shipping</li>
  <li>Discount</li>
  <li>Total</li>
</ul>
  </div>
  <div className="total-right">
  <ul>
  <li>&#8377; {totalAmount}</li>
                        <li>&#8377; {shippingCost!==0?shippingCost:`${shippingCost}(Free)`}</li>
                        <li>&#8377; -{couponAmount}</li>
                        <li style={{fontSize:'20px',fontWeight:600}}>&#8377; {finalAmount}</li>
</ul>
  </div>
</div>
      </div>
    </div>
  )
}

export default Checkout
