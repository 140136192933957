// App.js
import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SingleProduct from './pages/singleProduct/SingleProduct';
import Products from './pages/products/Products';
import Checkout from './pages/checkout/Checkout';
import About from './pages/other/About';
import Contact from './pages/other/Contact';
import Policy1 from './pages/other/Policy1';
import Policy2 from './pages/other/Policy2';
import Policy3 from './pages/other/Policy3';
import Policy4 from './pages/other/Policy4';
import Cart from './pages/cart/Cart';
import Error from './pages/error/Error';
import Orders from './pages/orders/Orders';
import Wishlist from './pages/wishlist/Wishlist';
import Search from './pages/products/Search';
import Collections from './pages/collections/Collections';
import Thank from './pages/thankyou/ThankYou';
function App() {

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <MainContent />
      <Footer />
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="zoom"
        timeout={300}
      >
        <div className="content">
          <Routes location={location}>
            <Route path='/' element={<Home />} />
            <Route path='/collections' element={<Collections />} />
            <Route path='/cart' element={<Cart />} />
            <Route path="/product/:handle" element={<SingleProduct />}/>
        <Route path="/products" element={<Search/>}/>
            <Route path="/collection/products/:handle" element={<Products />}/>
            <Route path='/checkout' element={<Checkout/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/privacy-policy' element={<Policy1/>}/>
            <Route path='/shipping-policy' element={<Policy2/>}/>
            <Route path='/return-exchange-policy' element={<Policy3/>}/>
            <Route path='/terms-of-service' element={<Policy4/>}/>
            <Route path='/orders' element={<Orders/>}/>
            <Route path='/wishlist' element={<Wishlist/>}/>
            <Route path='/thankyou' element={<Thank/>}/>
            <Route path='*' element={<Error/>}/>
          </Routes>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
