import React,{useContext,useEffect, useState} from 'react'
import {HeaderContext} from '../../context/HeaderContext'
import {getAPage} from '../../features/page/pageSlice'
import {useDispatch, useSelector} from 'react-redux'
import './other.css'
const Policy1 = () => {
  const {headTextSet}=useContext(HeaderContext)

  const dispatch=useDispatch()
  const pageState = useSelector((state) => state?.page?.singlePage);
  useEffect(()=>{
    dispatch(getAPage("66cc4360f8ecb6f94e292e3c"))
  },[])
  useEffect(()=>{
    headTextSet(pageState?.title)
      },[pageState])
  // console.log(desc)
  useEffect(() => {
    if (pageState?.metaTitle !== "") {
      document.title = pageState?.metaTitle;
    }
    else {
      document.title = "About";
    }
  
  }, [pageState]);
  useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/return-exchange-policy`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
  useEffect(() => {
    if (pageState?.metaDesc !== "") {
      document.querySelector('meta[name="description"]').setAttribute('content', pageState?.metaDesc);
    }
    else {
      document.querySelector('meta[name="description"]').setAttribute('content', document.createElement('div').innerHTML = "About Page");
    }
  
  }, [pageState]);
  return (
    <div className='policy'>
        <div className="banner">
            <h1>{pageState?.title}</h1>
        </div>
        <div className="content">
        <p dangerouslySetInnerHTML={{ __html:pageState?.desc }}/>
        </div>
    </div>
  )
}

export default Policy1
