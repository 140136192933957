import React,{useContext,useState,useEffect} from 'react'
import './product.css'
import { Link, useLocation } from 'react-router-dom';
import { TiHeartFullOutline } from "react-icons/ti";
import {HeaderContext} from '../../context/HeaderContext'
const Product = (props) => {
  const location=useLocation()
  const {wishLength,addToWishlist,removeToWishlist}=useContext(HeaderContext)
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
const [red,setRed]=useState("")
const [wishlist,setWishlist]=useState([])
useEffect(()=>{
  setWishlist(JSON.parse(localStorage.getItem("wishlist")))
},[])
const addProductToWishlistLocalStorage = (product) => {
  const existingCart = JSON.parse(localStorage.getItem("wishlist")) || [];
  const updatedCart = [...existingCart, product];
  localStorage.setItem("wishlist", JSON.stringify(updatedCart));
};
useEffect(()=>{
  wishlist?.map((item)=>{
    if(item?._id===props?.info?._id){
      setRed("h")
    }
  })
},[wishlist,wishLength])
const addToWish=async(data)=>{
  if(data && (data===props?.info?._id)){
    await addProductToWishlistLocalStorage(props?.info)
    addToWishlist(wishlist)
    setRed("h")

  }
}
const removeFromWish=(data)=>{
const updatedWishlist=wishlist?.filter(item=>{
  return !(item?._id===data)
}
)
setRed("")

localStorage.setItem("wishlist",JSON.stringify(updatedWishlist))
setWishlist(updatedWishlist)
removeToWishlist(wishlist)

}

  return (
    <div className='product-comp prdt'>
      <div className="top">
      <Link to={`/product/${props?.info?.handle}`}>
        <img src={modifyCloudinaryUrl(props?.info?.images[0]?.url)} alt="" />
        </Link>
        {
                  red===""?                <p className="wish-icon" onClick={(e)=>{addToWish(props?.info?._id)}}><TiHeartFullOutline className="cart-icon"/></p>
:
<p className="wish-icon" onClick={(e)=>{removeFromWish(props?.info?._id)}}><TiHeartFullOutline className="cart-icon" style={{color:'red'}}/></p>

                }
      </div>
      <div className="bottom">
<p className="name">{props?.info?.title}</p>
<p className="price"><span>Rs. {props?.info?.dashPrice}</span>Rs. {props?.info?.price}</p>
      </div>
    </div>
  )
}

export default Product
