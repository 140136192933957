import { createContext, useState } from 'react';

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [headText,setHeadText]=useState("")
  const headTextSet=(text)=>{
    setHeadText(text)
  }
  const cart=JSON.parse(localStorage.getItem("cart"))
  const wishlist=JSON.parse(localStorage.getItem("wishlist"))
  const [cartLength, setCartLength] = useState(cart?.length);
  const [wishLength,setWishLength]=useState(wishlist?.length)
  const [cartDrawOpen, setCartDrawOpen] = useState(false);
  const addToCarts = (item) => {
    setCartLength(item?.length + 1);
  };

  const removeFromCart=(item)=>{
    setCartLength(item?.length-1)
  }
  const addToWishlist = (item) => {
    setWishLength(item?.length + 1);
  }; 
  const removeToWishlist = (item) => {
    setWishLength(item?.length - 1);
  }; 
    return (
        <HeaderContext.Provider
          value={{
            headText,
            headTextSet,
            cartLength,
        addToCarts,
        removeFromCart,
        addToWishlist,
        wishLength,
        removeToWishlist
          }}
        >
          {children}
        </HeaderContext.Provider>
      );
}
export { HeaderProvider, HeaderContext };
