import React, { useContext,useState,useEffect } from 'react'
import './header.css'
import {useLocation, useNavigate,Link} from 'react-router-dom'
import { HeaderContext } from '../../context/HeaderContext';
import {getAllBanner} from '../../features/banner/bannerSlice'
import {useDispatch,useSelector} from 'react-redux'
import {getAllCollection} from '../../features/collection/collectionSlice'
import {getAllFeatured} from '../../features/products/productSlice'
import {words} from './words'
import Carousel from 'react-bootstrap/Carousel';
import { CiSearch } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { PiHandbagSimpleThin } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoHomeOutline } from "react-icons/io5";
import { CiGift } from "react-icons/ci";
import { RiBloggerLine } from "react-icons/ri";
import { CiCircleInfo } from "react-icons/ci";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { MdOutlineCollectionsBookmark } from "react-icons/md";

const Header = () => {
  const dispatch=useDispatch()
  const collectionState = useSelector((state) => state?.collection?.collection);
  const productState1=useSelector((state)=>state?.product?.featured?.data)
  const bannerState=useSelector((state)=>state?.banner?.banner)
const [slidercon,setSlidercon]=useState("")
  useEffect(()=>{
dispatch(getAllBanner())
dispatch(getAllCollection())
dispatch(getAllFeatured())
const a=JSON.parse(localStorage?.getItem("carousel"))
setSlidercon(a?.carou)
  },[])

  const {cartLength,wishLength}=useContext(HeaderContext)
  const navigate=useNavigate()
const [searchShow,setSearchShow]=useState(false)
const [headShow,setHeadShow]=useState("-100%")
const searchOpen=()=>{
  setSearchShow(true)
}
const searchClose=()=>{
  setSearchShow(false)
}
const headOpen=()=>{
  setHeadShow("0")
}
const headClose=()=>{
  setHeadShow("-100%")
}

const [svalue,setSvalue]=useState("")
const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    localStorage.setItem("search",JSON.stringify({
      mysearch:svalue
      
    }))
   
      navigate(`/products`)
      setSearchShow(false)
      setSvalue("")
  }
  
};
const searchValueClick = () => {
  localStorage.setItem("search",JSON.stringify({
    mysearch:svalue
    
  }))
 
    navigate(`/products`)
    setSearchShow(false)
    setSvalue("")

};

const [results, setResults] = useState([]);
useEffect(() => {
  if (svalue?.length > 1) {
    // Filter the words that contain the input value
    const filteredWords = words
      .filter((item) => item?.name?.toLowerCase()?.includes(svalue?.toLowerCase()))
      .slice(0, 5);

    setResults(filteredWords); // Store the filtered words in state
  } else {
    setResults([]); // Clear results when input is less than 2 characters
  }
}, [svalue]);

const rawContent =slidercon || ""
const cleanContent = rawContent.replace(/<\/?p>/g, ""); // Removes <p> and </p> tags
const contentArray = cleanContent.split(',').filter(item => item.trim() !== "");
  return (
    <div className='header'>
      <div className="overlay" style={{display:headShow==="0"?"block":"none"}}></div>
      <div className="mobile">
       <div className="header1">
       <div className="info">
        
       <Carousel data-bs-theme="light" indicators={false} fade interval={3000}>
    {contentArray.map((item, index) => (
        <Carousel.Item className='item' key={index}>
            {item.trim()} {/* Displaying the text directly */}
        </Carousel.Item>
    ))}
</Carousel>
       </div>
       <div className="up">
       <div className="left">
        <RxHamburgerMenu className='ham' onClick={(e)=>headOpen()}/>
        </div>
        <div className="head-img">
<Link to="/"><img src={bannerState[4]?.images[0]?.url} alt={bannerState[4]?.alt} /></Link>

        </div>
        <div className="right">
            <div className="wishlist">
            <CiSearch className='head-icon' onClick={searchOpen}/>
            </div>
            <div className="cart">
            <Link to="/cart"><PiHandbagSimpleThin className='head-icon'/><span style={{display:cartLength>0?"flex":"none"}}>{cartLength}</span></Link>
            </div>
        </div>
       </div>
       </div>
       <div className={`header3`}>
       <div className="info">
        
       <Carousel data-bs-theme="light" indicators={false} fade interval={3000}>
    {contentArray.map((item, index) => (
        <Carousel.Item className='item' key={index}>
            {item.trim()} {/* Displaying the text directly */}
        </Carousel.Item>
    ))}
</Carousel>
       </div>
        <div className="grids">
        <div className="left">
        <Link to="/"><img src={bannerState[4]?.images[0]?.url} alt={bannerState[4]?.alt} /></Link>
        </div>
        <div className="right">
            <ul>
            <Link to="/"><li>Home</li></Link>
            <Link to="/collections"><li>Collections</li></Link>
            {
            collectionState && collectionState?.map((item,index)=>{
              return <Link key={index} to={`/collection/products/${item?.handle}`} onClick={(e)=>headClose()}><li>{item?.category}</li></Link>
            })
          }
            </ul>
            
        </div>
        <div className="center">
          <div onClick={searchOpen}>
          <CiSearch className='head-icon'/>
          </div>
          <div className="wishlist">
            <Link to="/wishlist"><CiHeart className='head-icon'/><span style={{display:wishLength>0?"flex":"none"}}>{wishLength}</span></Link>
            </div>
            <div className="cart">
            <Link to="/cart"><PiHandbagSimpleThin className='head-icon'/><span style={{display:cartLength>0?"flex":"none"}}>{cartLength}</span></Link>
            </div>
        <button onClick={()=>navigate("/orders")}>Orders</button>
        </div>
        </div>
       </div>
       <div className="search-head" style={{top:searchShow?"0":"-120%"}}>
        <IoCloseOutline className='close' onClick={searchClose}/>
        <div className="search-bar">
<input type="text" placeholder='Search for products....' value={svalue} onChange={(e)=>setSvalue(e.target.value)} onKeyDown={handleKeyDown}/>
<button onClick={searchValueClick}>Search</button>
        </div>
        <div className="recent-search">
          <p>Recent Searches</p>
          <ul>
          {
            results?.map((item,index)=>{
              return <li onClick={(e)=>setSvalue(item?.value)}>
              <p><CiSearch className='icons'/></p>
              <p >{item?.name}</p>
            </li>
            })
          }
            
          </ul>
        </div>
        <div className="trending-collections">
        <p className="trending-heads">
          Explore Trending Products
        </p>
        <div className="trend-list">
          {
            productState1 && productState1?.slice(0,4)?.map((item,index)=>{
              return <Link to={`/product/${item?.handle}`}>
              <div className="prdt">
              <img src={item?.images[0]?.url} alt="" />
              <p className='name'>{item?.title}</p>
              <p className="price">Rs. {item?.price}</p>
            </div>
              </Link>
            })
          }
          
          
        </div>
      </div>
       </div>
       <div className="toggle-head" style={{left:headShow}}>
        <div className="close" onClick={(e)=>headClose()}>
        <IoCloseOutline className='ham-close'/>
        </div>
        <ul>
          <Link to="/" onClick={(e)=>headClose()}><IoHomeOutline className='menu-icon'/><li>Home</li></Link>
          {
            collectionState && collectionState?.map((item,index)=>{
              return <Link key={index} to={`/collection/products/${item?.handle}`} onClick={(e)=>headClose()}><MdOutlineCollectionsBookmark className='menu-icon'/><li>{item?.category}</li></Link>
            })
          }
                    <Link to="/collections" onClick={(e)=>headClose()}><MdOutlineCollectionsBookmark className='menu-icon'/><li>All Collections</li></Link>
                    <Link to="/wishlist" onClick={(e)=>headClose()}><CiHeart className='menu-icon'/><li>Wishlist ({wishLength})</li></Link>
          <Link to="/orders" onClick={(e)=>headClose()}><CiGift className='menu-icon'/><li>Orders</li></Link>
          <Link to="/blogs" onClick={(e)=>headClose()}><RiBloggerLine className='menu-icon'/><li>Blogs</li></Link>
          <Link to="/about" onClick={(e)=>headClose()}><CiCircleInfo className='menu-icon'/><li>About</li></Link>
          <Link to="/contact" onClick={(e)=>headClose()}><MdOutlinePhoneInTalk className='menu-icon'/><li>Contact</li></Link>
        </ul>
       </div>
      </div>
    </div>
  )
}

export default Header
