import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallratings } from '../../features/products/productSlice';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import './home.css';

const Reviews = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 2,
    },
  };

  const dispatch = useDispatch();
  const getRatings = useSelector((state) => state?.product?.getratings);

  useEffect(() => {
    dispatch(getallratings());
  }, [dispatch]);

  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };

  return (
    <div className="reviews">
      <p className="trending-heads">Let Our Customer Speak for Us</p>
      <div className="r-sec">
        {getRatings && getRatings.length > 0 ? ( // Check if getRatings is defined and has data
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {getRatings.map((item, index) => (
              <Link to={`/products/${item?.productId}`} key={index}>
                <div className="rev">
                  <img
                    src={modifyCloudinaryUrl(item?.productImages && item?.productImages[0]?.url)}
                    alt="review"
                  />
                  <Stack spacing={1} className="stars">
                    <Rating name="size-small" value={item?.star} size="small" />
                  </Stack>
                  <p className="name">{item?.name}</p>
                  <p className="para">{item?.comment}</p>
                </div>
              </Link>
            ))}
          </Carousel>
        ) : (
          <p>No reviews available</p> // Fallback if there are no reviews
        )}
      </div>
    </div>
  );
};

export default Reviews;
