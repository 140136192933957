import React, { useEffect, useState,useContext } from 'react'
import './sproduct.css'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-bootstrap/Carousel';
import { getAProduct, rating, getAllProducts } from '../../features/products/productSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LuPlus } from "react-icons/lu";
import { LuMinus } from "react-icons/lu";
import { MdOutlineStraighten } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { HeaderContext } from '../../context/HeaderContext';
import Product from '../../components/product/Product';
import toast from 'react-hot-toast'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
// import img1 from '../../images/1.jpg'
// import img2 from '../../images/2.jpg'
// import img3 from '../../images/3.jpg'
// import img4 from '../../images/4.jpg'
// import img5 from '../../images/5.jpg'
// import img6 from '../../images/6.jpg'
// import img7 from '../../images/7.jpg'
// import img8 from '../../images/8.jpg'
// import img9 from '../../images/9.jpg'
import loadingImg from '../../images/loading.png'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SingleProduct = () => {
  const productState=useSelector((state)=>state?.product?.product)
  const [open, setOpen] = useState(false);
  const [chart, setChart] = useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { handle } = useParams()
  const [pQuantity, setPQuantity] = useState()
  const [color, setColor] = useState(null)
  const [size, setSize] = useState(null)
  const [sold, setSold] = useState("none")
  const [quantity, setQuantity] = useState(1)
  const [alreadyAdded, setAlreadyAdded] = useState(false)
  const [mainImage, setMainImage] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [msg, setMsg] = useState("")
  const [star, setStar] = useState(5)

  const navigate = useNavigate()
  const singleProductState = useSelector((state) => state?.product?.getSingleProduct)
  const location = useLocation()
  const getProductId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const {headTextSet,addToCarts}=useContext(HeaderContext)
  useEffect(()=>{
headTextSet(singleProductState?.title)
  },[singleProductState,getProductId])
  useEffect(() => {
    dispatch(getAProduct(handle))
  }, [dispatch, handle,getProductId])

  // useEffect(() => {
  //   if (singleProductState?.collectionName) {
  //     const imgMap = {
  //       "Men's Loafers": img1,
  //       "Men's Denim Jeans": img2,
  //       "Men's Premium Shirts": img4,
  //       "Men's Premium T Shirts": img4,
  //       "Men's Sweatshirts": img4,
  //       "Men's Slippers": img1,
  //       "Men's Sneakers": img1,
  //       "Men's Hoodies": img4,
  //       "Men's Trackpants": img2,
  //       "Men's Co-ord Set": img4,
  //       "Men's Premium Shorts": img3,
  //       "Men's Jackets": img4,
  //       "Women's Co-ord set": img8,
  //       "Women's T-Shirt": img8,
  //       "Women's Dresses": img5,
  //       "Flat Sandals": img6,
  //       "Women's Sandals": img6,
  //       "Women's Legging": img7,
  //       "Women's Sweatshirts": img8,
  //       "Women's Winter Coats": img8,
  //       "Men's Belt": img9,
  //       "Men's Premium Half Sleeve Shirt": img4,
  //     };
  //     setChart(imgMap[singleProductState.collectionName] || '');
  //   }
  // }, [singleProductState?.collectionName]);
  useEffect(() => {
    if (!color || !size) {
      setAlreadyAdded(false);
      return;
    }
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const matchingCartItem = cart?.find(item => {
      return item?.product?._id === singleProductState?._id && item?.color === color && item?.size === size;
    });
    if (matchingCartItem) {
      setAlreadyAdded(true);
    } else {
      setAlreadyAdded(false);
    }
  }, [color, size, getProductId,singleProductState]);

  const addProductToCartLocalStorage = (product) => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCart = [...existingCart, product];
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };
  const cart=JSON.parse(localStorage.getItem("cart"))
  const addTocart = async (data) => {
    if (color === null) {
      toast.error("Please Select Color")
      return false
    }
    if (size === null) {
      toast.error("Please Select Size")
      return false
    }
    else {
      addToCarts(cart)
      await addProductToCartLocalStorage({ productId: data, color, quantity, price: singleProductState?.price, size, product: singleProductState })
      toast.success("Added To Cart")
      // window.fbq('track', 'AddToCart', {
      //   content_name: `${singleProductState?.title}`,
      //   content_category: `${singleProductState?.category}`,
      //   content_ids: `${singleProductState?._id}`,
      //   content_type: 'product',
      //   value: `${singleProductState?.price}`,
      //   currency: 'INR'
      // });
      setAlreadyAdded(true)

    }


  }
  const buyNow = async (data) => {
    if (color === null) {
      toast.error("Please Select Color")
      return false
    }
    if (size === null) {
      toast.error("Please Select Size")
      return false
    }
    else {
      await addProductToCartLocalStorage({ productId: data, color, quantity, price: singleProductState?.price, size, product: singleProductState })
      toast.success("Added To Cart")
      // window.fbq('track', 'InitiateCheckout', {
      //   content_name: `${singleProductState?.title}`,
      //   content_category: `${singleProductState?.category}`,
      //   content_ids: `${singleProductState?._id}`,
      //   content_type: 'product',
      //   value: `${singleProductState?.price}`,
      //   currency: 'INR'
      // });
      setTimeout(() => {
        navigate('/checkout')
      }, 1000)
    }


  }
  const changeMainImage = (img) => {
    setMainImage(img?.url)

    setTimeout(() => {
      setMainImage("")
    }, 5000)
  }

  const findVariant = (color, size) => {
    return singleProductState?.variants.find(variant => variant.color === color && variant.size === size);
  };
  useEffect(() => {
    const matchingVariant = findVariant(color, size);
    setPQuantity(matchingVariant?.quantity)
    if (matchingVariant?.quantity === 0) {
      setSold("block")

    }
    else {
      setSold("none")

    }
  }, [color, size, findVariant])
  useEffect(() => {
    // window.fbq('track', 'ViewContent', {
    //   content_name: `${singleProductState?.title}`,
    //   content_category: `${singleProductState?.category}`,
    //   content_ids: `${singleProductState?._id}`,
    //   content_type: 'product',
    //   value: singleProductState?.price,
    //   currency: 'INR'
    // });
  }, [singleProductState])
  useEffect(() => {
    if (singleProductState?.variants) {
      const firstAvailableVariant = singleProductState?.variants?.find(variant => variant.quantity > 0);
      if (firstAvailableVariant) {
        setColor(firstAvailableVariant.color);
        setSize(firstAvailableVariant.size);
      }
    }
  }, [singleProductState?.variants]);
  const incrementQuantity = () => {
    if (quantity < pQuantity) {
      setQuantity(quantity + 1);
    }
  };
  useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/product/${handle}`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, [handle]);
  useEffect(()=>{
dispatch(getAllProducts({limit:4,collectionName:singleProductState?.collectionName?.slice(",")[0],sort:"-createdAt",page:1}))
  },[singleProductState?.collectionName])

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }
  useEffect(() => {
    if (singleProductState?.metaTitle !== "") {
      document.title = singleProductState?.metaTitle;
    }
    else {
      document.title = singleProductState?.title;
    }

  }, [singleProductState?.metaTitle]);

  useEffect(() => {
    if (singleProductState?.metaDesc !== "") {
      document.querySelector('meta[name="description"]').setAttribute('content', singleProductState?.metaDesc);
    }
    else {
      document.querySelector('meta[name="description"]').setAttribute('content', document.createElement('div').innerHTML = singleProductState?.description);
    }

  }, [singleProductState?.metaDesc]);

  const commentPost = () => {
    if (name === "" || email === "" || msg === "") {
      toast.info("Please Fill all the Fields")
    }
    else {
      dispatch(rating({ name: name, email: email, comment: msg, star: star, prodId: singleProductState?._id }))
      setTimeout(() => {
        setMsg("")
        setName("")
        setEmail("")
      }, 500)
    }
  }

  const [loading, setLoading] = useState(true)
  const productStat = useSelector((state) => state?.product);

  const { isLoading, isSuccess } = productStat
  useEffect(() => {
    if (isLoading && singleProductState) {
      setLoading(true)
    }
    if (isSuccess && singleProductState) {
      setTimeout(() => {
        setLoading(false)
      }, 800)
    }
  }, [isLoading, isSuccess, singleProductState])


  const [alt, setAlt] = useState("")
  useEffect(() => {
    if (singleProductState?.alt && singleProductState?.alt !== "") {
      setAlt(singleProductState?.alt)
    }
    else {
      setAlt(singleProductState?.title)
    }

  }, [singleProductState?.title, singleProductState?.alt]);
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  return (
    <div className='single-product'>
      <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <div className="chart">
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className='size-img'>
            <div className="c-icon" onClick={handleClose}><IoCloseOutline /></div>
            <img src={chart} alt="" />
          </DialogContent>
        </Dialog>
      </div>
      <div className="product">
        {
          loading === true ? <p style={{ width: '100%', height: '400px', backgroundColor: 'rgb(228, 228, 228)', borderRadius: '10px' }} className='prdt-left'></p> :
            <div className="prdt-left">
              <div className="main">
                {
                  mainImage === "" ? <Carousel data-bs-theme="dark" indicators={false}>
                    {
                      singleProductState?.images?.map((item) => {
                        return <Carousel.Item interval={3000}>
                          <img src={modifyCloudinaryUrl(item?.url)} alt="" />
                        </Carousel.Item>
                      })
                    }
                  </Carousel>
                    :
                    <img src={modifyCloudinaryUrl(mainImage)} alt={alt} />
                }
              </div>
              <div className="thumbs">
                {
                  singleProductState?.images?.map((img, index) => {
                    return <img src={modifyCloudinaryUrl(img?.url)} alt={alt} key={index} onClick={() => changeMainImage(img)} />
                  })
                }
              </div>
            </div>
        }
        <div className="prdt-right">
          <h1 className="product-name">{singleProductState?.title}</h1>
          <div className='price'>
            <p>Rs. {(singleProductState?.price) * 2}</p>
            <p className="prdt-price">Rs. {singleProductState?.price}</p>
            <p style={{display: sold, margin: '0 10px', backgroundColor: 'rgb(37, 37, 37)',color: 'white',borderRadius: '4px',padding: '0px 10px',height: '24px'
            }}>Sold out</p>
          </div>
          <div className="prdt-desc">
            <p dangerouslySetInnerHTML={{ __html: singleProductState?.content }} />
          </div>
          <div className="size prdt-variation">
            <p>SIZE : <span onClick={handleClickOpen}><MdOutlineStraighten className='ico' /> Size Chart</span></p>
            <ul>
              {singleProductState?.variants
                .filter(variant => variant.color === color) // Filter variants based on selected color
                .map((variant, index) => (
                  <li key={index} onClick={() => {
                      if (variant.quantity > 0) {
                        setSize(variant.size);}}}
                    style={{border: variant.size === size ? '2px solid black' : '1px solid grey',color: variant.size === size ? 'black' : 'rgb(122, 122, 122)',opacity: variant.quantity === 0 ? 0.5 : 1,textDecoration: variant.quantity === 0 ? 'line-through' : 'none',textDecorationThickness: variant.quantity === 0 ? '1px' : 'auto',pointerEvents: variant.quantity === 0 ? 'none' : 'auto',}}>{variant.size}</li>))}
            </ul>
          </div>
          <div className="color prdt-variation">
            <p>COLOR :</p>
            <ul>
              {
                singleProductState?.variants?.filter((item, index, arr) => arr.findIndex(i => i.color === item.color) === index)
                  .map((item, index) => <li onClick={() => (setColor(item.color), setQuantity(1))} key={index} style={{ border: item.color === color ? '2px solid black' : '1px solid grey', color: item.size === size ? 'black' : 'rgb(122, 122, 122)' }}>{item.color}</li>)
              }
            </ul>
          </div>
          <div className="quantity">
            <p onClick={decrementQuantity}><LuMinus className='qty-icon' /></p>
            <p>{quantity}</p>
            <p onClick={incrementQuantity} style={{ opacity: pQuantity === quantity ? 0 : 1 }}><LuPlus className='qty-icon' /></p>
          </div>
          {pQuantity < 3 ? <p style={{ color: 'red', textAlign: 'center', marginTop: '15px' }}>Only {pQuantity} Available</p>:""}
          {
            sold === "block" ? <p style={{ textAlign: 'center', margin: "15px auto", color: 'red', fontWeight: 600, fontSize: '20px' }}>This size is not available</p> :
              <div className="buy-btn">
                <button onClick={() => { alreadyAdded ? navigate('/cart') : addTocart(singleProductState?._id) }} className={"btns"} >{alreadyAdded ? "GO TO CART" : "ADD TO CART"}</button>
                <button className={"btns"} onClick={() => { alreadyAdded ? navigate('/checkout') : buyNow(singleProductState?._id) }}>{alreadyAdded ? "COMPLETE PURCHASE" : "BUY IT NOW"}</button>
              </div>}
          <div className="prdt-desc">
            <p dangerouslySetInnerHTML={{ __html: singleProductState?.description }} />
          </div>
        </div>
      </div>
      <div className="ratings">
        <p style={{ fontSize: '20px', fontWeight: 500 }}>Ratings</p>
        <div className="rating">
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter Name' />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter Email' />
          <Stack spacing={1} className="stars">
            <Rating name="size-small" value={star} size="medium" onChange={(e) => setStar(e.target.value)} />
          </Stack>
          <div className="msg">
            <textarea name="" id="" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder='Enter Message'></textarea>
          </div>
          <button onClick={commentPost}>Post</button>
        </div>
        <hr />
        {
          singleProductState?.ratings?.length > 0 ?
            <div className="ratingCount">
              <p style={{ fontSize: '20px', fontWeight: 500 }}>What Our Customers Says.</p>
              {
                singleProductState?.ratings?.map((item, index) => {
                  return (
                    <div className="rate">
                      <div className="name">
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>{item?.name}</p>
                        <Stack spacing={1} className="star" style={{ fontSize: '25px', marginLeft: '20px' }}>
                          <Rating name="size-small" value={item?.star} size="medium" onChange={(e) => setStar(e.target.value)} />
                        </Stack>
                      </div>
                      <p style={{ color: 'grey', marginTop: '15px', fontSize: '14px' }}>{item?.comment}</p>
                    </div>
                  )
                })
              }
            </div>
            :
            ""
        }
      </div>
      <div className="section-heads">
        <p>You May Also Like</p>
      </div>
      <div className="products-list">
        {
          productState?.products?.map((item,index)=>{
            return <Product info={item}/>
          })
        }
    </div>
        </div>
  )
}
export default SingleProduct
