import axios from 'axios'
import {base_url, config} from '../../utils/axiosConfig'

const getCollection=async()=>{
    
    const response =await axios.get(`${base_url}collection/`)


        if (response.data) {
            return response.data;
        }
}
const getSingleCollection=async(data)=>{
    
    const response =await axios.get(`${base_url}collection/web/${data.getCollectionId}`)


        if (response.data) {
            return response.data;
        }
}
const getInnerCollection=async(data)=>{
    
    const response =await axios.get(`${base_url}collection/web/${data.getCollectionId}/inner/${data.getInnerCollectionId}`)


        if (response.data) {
            return response.data;
        }
}

export const collectionService={
    getCollection,
    getSingleCollection,
    getInnerCollection
}