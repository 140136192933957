import React,{useContext,useEffect, useState} from 'react'
import {HeaderContext} from '../../context/HeaderContext'
import {getAPage} from '../../features/page/pageSlice'
import {useDispatch, useSelector} from 'react-redux'
import './other.css'
const About = () => {
  const {headTextSet}=useContext(HeaderContext)
  const dispatch=useDispatch()
  useEffect(()=>{
headTextSet("About")
  },[])
  const pageState = useSelector((state) => state?.page?.singlePage);
useEffect(()=>{
  dispatch(getAPage("66cc4a307c4629b27c9adc40"))
},[])

// console.log(desc)
useEffect(() => {
  if (pageState?.metaTitle !== "") {
    document.title = pageState?.metaTitle;
  }
  else {
    document.title = "About";
  }

}, [pageState]);
useEffect(() => {
  if (pageState?.metaDesc !== "") {
    document.querySelector('meta[name="description"]').setAttribute('content', pageState?.metaDesc);
  }
  else {
    document.querySelector('meta[name="description"]').setAttribute('content', document.createElement('div').innerHTML = "About Page");
  }

}, [pageState]);


useEffect(() => {
  const canonicalUrl =`https://lybrraa.com/about`;
  let canonicalLink = document.querySelector('link[rel="canonical"]');

  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalUrl);
  } else {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', canonicalUrl);
    document.head.appendChild(canonicalLink);
  }
}, []);
  return (
    <div className='about-page'>
      <div className="banner">
        <h1>About Us</h1>
      </div>
      <div className="content">
            <p className="heads">Who We Are?</p>
            <div className="info">
<p dangerouslySetInnerHTML={{ __html:pageState?.desc }}/>
            </div>
      </div>
    </div>
  )
}

export default About
