import React,{useContext,useEffect} from 'react'
import './thankyou.css'
import {useNavigate} from 'react-router-dom'
import { HeaderContext } from '../../context/HeaderContext';

const Thank = () => {
const navigate=useNavigate()
const {headTextSet}=useContext(HeaderContext)
useEffect(()=>{
headTextSet("Thank You")
},[])
  return (
    <div className='thank'>
      <div className="thank-div">
        <h1>Thank You !</h1>
        <p>Thank you for visting <span>LYBRRAA</span> website</p>
  <p>Your Order is placed successfully</p>
        <p>You will recieved an email message sortly</p>
        <img src="https://i.pinimg.com/originals/d6/4f/60/d64f6038a5849a31279ce97358240d97.gif" alt="" />
        <h2>Check your Email</h2>
        <p>If you did't recieve any mail, contact <span>lybrraa@gmail.com</span></p>
        <button onClick={()=>navigate("/orders")}><span>Track Order</span></button>
      </div>
    </div>
  )
}

export default Thank
