import React, { useContext,useEffect } from 'react'
import './error.css'
import {HeaderContext} from '../../context/HeaderContext'
const Error = () => {
    const {headTextSet}=useContext(HeaderContext)
    useEffect(()=>{
headTextSet("404 ERROR")
    },[])
    useEffect(() => {
      const canonicalUrl =`https://lybrraa.com/404`;
      let canonicalLink = document.querySelector('link[rel="canonical"]');
  
      if (canonicalLink) {
        canonicalLink.setAttribute('href', canonicalUrl);
      } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
      }
  }, []);
  return (
    <div className='error'>
      <img src="https://freefrontend.com/assets/img/html-funny-404-pages/CodePen-404-Page.png" alt="" />
    </div>
  )
}

export default Error
