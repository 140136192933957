import React, { useEffect } from 'react'
import './home.css'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reviews from './Reviews';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {getProductsAll,getAllFeatured} from '../../features/products/productSlice'
import {getAllCollection} from '../../features/collection/collectionSlice'
import Product from '../../components/product/Product';
import {Link} from 'react-router-dom'
import {getAPage} from '../../features/page/pageSlice'

const Home = () => {
  const dispatch=useDispatch()
  const collectionState = useSelector((state) => state?.collection?.collection);
  const productState1=useSelector((state)=>state?.product?.featured?.data)
  const bannerState=useSelector((state)=>state?.banner?.banner)
  const pageState = useSelector((state) => state?.page?.singlePage);

useEffect(()=>{
  dispatch(getProductsAll({limit:12,page:1}))
  dispatch(getAllFeatured())
  dispatch(getAllCollection())
dispatch(getAPage("66d54e3a3aeb7d6251115937"))

},[])

useEffect(() => {
  if (pageState?.desc) {
    localStorage.setItem("carousel", JSON.stringify({ carou: pageState.desc }));
  }
}, [pageState]);
useEffect(() => {
  const canonicalUrl =`https://lybrraa.com/`;
  let canonicalLink = document.querySelector('link[rel="canonical"]');

  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalUrl);
  } else {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', canonicalUrl);
    document.head.appendChild(canonicalLink);
  }
}, []);

useEffect(() => {
      document.title = "Lybrraa";
      document.querySelector('meta[name="description"]').setAttribute('content',"Lybrraa is the best online shopping platform. Discover premium quality products, unbeatable prices, and fast delivery.");



}, []);


  return (
    <>
    
    <div className='home'>
      <div className="slider">
      <Carousel data-bs-theme="light" indicators={false}>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[0]?.images[0]?.url}
          alt={bannerState[0]?.alt}
        />
      </Carousel.Item>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[1]?.images[0]?.url}
          alt={bannerState[1]?.alt}
        />
      </Carousel.Item>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[2]?.images[0]?.url}
          alt={bannerState[2]?.alt}
        />
      </Carousel.Item>
    </Carousel>
      </div>
      <div className="products">
      <p className='section-heads'>OUR FEATURED  PRODUCTS</p>
          <div className="f-products">
            {
              productState1?.map((item,index)=>{
                return <Product info={item}/>
              })
            }
            
          </div>
      </div>
      <div className="featured-banner">
        <div className="handle-div">
<img src={bannerState[3]?.images[0]?.url} alt={bannerState[4]?.alt} />
          
        </div>
      </div>
      {
        collectionState && collectionState?.map((item,index)=>{
          return <div className="latest-products">
          <p className="section-heads">{item?.category}</p>
          <div className="view-all">
          <Link to={`/collection/products/${item?.handle}`}><button>VIEW ALL</button></Link>
            <KeyboardDoubleArrowRightIcon/>
          </div>
          <div className="l-products">
          {
              item?.productCount?.map((items,index)=>{
                return <Product info={items}/>
              })
            }
          
            
          </div>
          
        </div>
        })
      }
      
      <Reviews/>
      <div className="shipping">
        <div>
          <img src="https://cdn-icons-png.flaticon.com/512/11237/11237529.png" alt="" />
          <p>Fast Shipping</p>
        </div>
        <div className='b-div'>
          <img src="https://icones.pro/wp-content/uploads/2022/08/icone-de-cadenas-de-securite-jaune.png" alt="" />
          <p>Secure Payments</p>
        </div>
        <div>
          <img src="https://cdn-icons-png.flaticon.com/512/9716/9716259.png" alt="" />
          <p>24/7 Support</p>
        </div>
      </div>
    </div>
    <div className="home1">
    <div className="slider">
      <Carousel data-bs-theme="light" indicators={false}>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[0]?.images[0]?.url}
          alt={bannerState[0]?.alt}
        />
      </Carousel.Item>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[1]?.images[0]?.url}
          alt={bannerState[1]?.alt}
        />
      </Carousel.Item>
      <Carousel.Item className='item'>
        <img
          className="d-block w-100"
          src={bannerState[2]?.images[0]?.url}
          alt={bannerState[2]?.alt}
        />
      </Carousel.Item>
    </Carousel>
      </div>
     
      <div className="trending-collections">
        <p className="trending-heads">
          Explore Trending Products
        </p>
        <div className="trend-list">
        {
              productState1?.slice(0,4)?.map((item,index)=>{
                return <Product info={item}/>
              })
            }
        </div>
      </div>
      
      {
        collectionState && collectionState?.map((item,index)=>{
          return  <div>
                      <div className="trend-banner">
        <img src={item?.banner[1]?.url} alt={item?.category} />
      </div>
          <div className="trending-collections">
          <p className="trending-heads">
            {item?.category}
          </p>
          <div className="view-all">
            <Link to={`/collection/products/${item?.handle}`}><button>VIEW ALL</button></Link>
            <KeyboardDoubleArrowRightIcon/>
          </div>
          <div className="trend-list">
          {
              item?.productCount?.map((items,index)=>{
                return <Product info={items}/>
              })
            }
          </div>
          
        </div>
          </div>

        })
      }
     
          <Reviews/>
          <div className="shipping">
        <div>
          <img src="https://cdn-icons-png.flaticon.com/512/11237/11237529.png" alt="" />
          <p>Fast Shipping</p>
        </div>
        <div className='b-div'>
          <img src="https://icones.pro/wp-content/uploads/2022/08/icone-de-cadenas-de-securite-jaune.png" alt="" />
          <p>Secure Payments</p>
        </div>
        <div>
          <img src="https://cdn-icons-png.flaticon.com/512/9716/9716259.png" alt="" />
          <p>24/7 Support</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Home
