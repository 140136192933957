import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import pageService from "./pageService";
import {toast} from 'react-hot-toast'
let loadingToast = null; // Reference to the loading toast
export const getPages = createAsyncThunk(
  "page/get-pages",
  async (thunkAPI) => {
    try {
      return await pageService.getPages();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAPage = createAsyncThunk(
  "page/get-page",
  async (id, thunkAPI) => {
    try {
      return await pageService.getPage(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const pageState = {
  page: "",
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const pageSlice = createSlice({
  name: "page",
  initialState:pageState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.pages = action.payload;
      })
      .addCase(getPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singlePage = action.payload;
      })
      .addCase(getAPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => pageState);
  },
});
export default pageSlice.reducer;
