import React,{useEffect, useState,useContext} from 'react'
import './products.css'
import Product from '../../components/product/Product'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useLocation,useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getsingleCollection} from '../../features/collection/collectionSlice'
import { getAllProducts,resetState } from '../../features/products/productSlice';
import loadingImg from '../../images/loading.png'
import TuneIcon from '@mui/icons-material/Tune';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderContext } from '../../context/HeaderContext';
const Products = () => {
  const {headTextSet}=useContext(HeaderContext)

const location=useLocation()
  const queryParams = new URLSearchParams(location.search);
  let page = parseInt(queryParams.get('page')) || 1;  
  const [filterOpen,setFilterOpen]=useState("-100%")
  const [size,setSize]=useState("")
  const [color,setColor]=useState("")
  const [menu,setMenu]=useState("size")
  const [sort,setSort]=useState("-createdAt")
  const [sortOpen,setSortOpen]=useState("-100%")
  const colorMenu=["Red","Yellow","Green","Orange","White","Black","Grey","Blue","Purple","Pink","Brown"]

const [collectionName,setCollectionName]=useState("")
const navigate=useNavigate()
  const collectionState=useSelector((state)=>state?.collection?.singlecollection)
  const getCollectionId = location.pathname.split("/")[3];
  const dispatch=useDispatch();
const productState=useSelector((state)=>state?.product?.product)
useEffect(() => {
  dispatch(resetState());
}, [dispatch]);

useEffect(() => {
  dispatch(getsingleCollection({getCollectionId}));
}, [dispatch, getCollectionId]);
useEffect(()=>{
    setCollectionName(collectionState?.title)
},[collectionState])

useEffect(() => {
  const element = document.scrollingElement || document.documentElement;
  element.style.scrollBehavior = 'auto'; // Disable smooth scrolling
  element.scrollTop = 0; // Scroll to the top instantly
  element.style.scrollBehavior = 'auto';

}, [location,page]);
const uniqueSizes = new Set();

  productState?.products?.forEach(product => {
    product.variants.forEach(variant => {
      uniqueSizes.add(variant.size);
    });
  });

  // Convert the Set to an array
  const uniqueSizesArray = Array.from(uniqueSizes);


const handlePageChange=(e,v)=>{
  updateURL(v)
}

const toggleSize=(sizes)=>{
  if(size===sizes){
    setSize("")
  }
  else{
    setSize(sizes)
  }
}
const toggleColor=(colors)=>{
  if(color===colors){
    setColor("")
  }
  else{
    setColor(colors)
  }
}

const toggleMenu=(menu)=>{
  setMenu(menu)
}
const toggleSort=(sort)=>{
  setSort(sort)
  setSortOpen("-100%")
}

const isSelectedSize = (sizes) => size===sizes ? 'selected' : '';
const isSelectedColor = (colors) => color===colors ? 'selected' : '';
const iseSelectedMenu = (menus) => menu===menus ? 'selected' : '';
const isSelectedSort = (sorts) => sort===sorts ? 'selected' : '';

const openFilter=()=>{
  setFilterOpen("0")
}
const closeFilters=()=>{
  setFilterOpen("-100%")
}
const openSorting=()=>{
  setSortOpen("0")
}
const closeSorting=()=>{
  setSortOpen("-100%")
}
const closeMenu=()=>{
  setFilterOpen("-100%")
  setSortOpen("-100%")
}
const [load,setLoad]=useState(true)
useEffect(()=>{
if(productState?.products?.length>0){
  setTimeout(()=>{
setLoad(false)
  },2000)
}
},[productState,getCollectionId])

useEffect(()=>{
  dispatch(resetState())
},[dispatch])
const updateURL = (sizeNumber) => {
  const searchParams = new URLSearchParams();

  // Add page parameter
  searchParams.set('page', sizeNumber);

  // Add filter parameters
  if (size!=="") {
    searchParams.set('size', size);
  }
  if (color!=="") {
    searchParams.set('color', color);
  }

  navigate(`${location.pathname}?${searchParams.toString()}`);
};
useEffect(() => {
  const canonicalUrl =`https://lybrraa.com/collection/products/${getCollectionId}`;
  let canonicalLink = document.querySelector('link[rel="canonical"]');

  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalUrl);
  } else {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', canonicalUrl);
    document.head.appendChild(canonicalLink);
  }
}, [getCollectionId]);
useEffect(()=>{
  const filter = {};

  // Add selected sizes to the filter object
  if (size!=="") {
    filter.size = size;
  }

  // Add selected colors to the filter object
  if (color!=="") {
    filter.color = color;
  }

  // Add selected brands to the filter object

      dispatch(getAllProducts({sort,limit:24,page,collectionName:collectionName,...filter}))
  
},[page,collectionName,sort])

const applyFilter = () => {
  const filter = {};

  // Add selected sizes to the filter object
  if (size!=="") {
    filter.size = size;
  }

  // Add selected colors to the filter object
  if (color!=="") {
    filter.color = color;
  }

  // Add selected brands to the filter object

  const searchParams = new URLSearchParams(location.search);
  if (filter.size) {
    searchParams.set('size', filter.size);
  } else {
    searchParams.delete('size');
  }
  if (filter.color) {
    searchParams.set('color', filter.color);
  } else {
    searchParams.delete('color');
  }


  navigate(`${location.pathname}?${searchParams.toString()}`);

    dispatch(getAllProducts({ sort, limit:24, page, collectionName, ...filter }));

  

  closeFilters()
};
useEffect(()=>{
  headTextSet(collectionName)
    },[collectionName])

const clearFilter=()=>{
  setSize("")
  setColor("")
    dispatch(getAllProducts({ sort: sort, limit:24, page, collectionName }));

  navigate(location.pathname);  // Clears all query parameters
  closeFilters()
}



useEffect(() => {
  if (collectionState?.metaTitle!=="") {
      document.title = collectionState?.metaTitle;
  }
  else{
    document.title =collectionState?.title;
  }

}, [collectionName]);
useEffect(() => {
if (collectionState?.metaDesc!=="") {
    document.querySelector('meta[name="description"]').setAttribute('content',collectionState?.metaDesc);
}
else{
  document.querySelector('meta[name="description"]').setAttribute('content',document.createElement('div').innerHTML = collectionState?.title );
}

}, [collectionName]);

useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const sizesParam = queryParams.get('size');
  const colorsParam = queryParams.get('color');

  if (sizesParam) {
    setSize(sizesParam);
  } else {
    setSize("");
  }

  if (colorsParam) {
    setColor(colorsParam);
  } else {
    setColor("");
  }


}, [location.search]);
const [col,setCol]=useState("")
const toggleCol=(cols)=>{
  setCol(cols)
  setCollectionName(cols)
}
const isSelectedCol = (cols) => col===cols ? 'selected' : '';
useEffect(()=>{
if(col!==""){
  dispatch(getAllProducts({ page: page, limit: 24, collectionName:col,size,color,sort }));
}
},[col])
  return (
    <div className='main-products'>
      {
        load===false?
<div className='mobile'>
  {(filterOpen==="0" || sortOpen==="0")?<div className='overlay1' onClick={closeMenu}></div>:""}
  <div className="page-name">
    <div className="col-desc">
    <p dangerouslySetInnerHTML={{ __html: collectionState?.desc }} />
    </div>
    {
      collectionState?.collections?.map((item,index)=>{
        return <div onClick={()=>toggleCol(item?.title)} className={isSelectedCol(item?.title)}>
          <p >{item?.title}</p>
        </div>
      })
    }
  </div>
  <div className="filter">
    <p className="option" onClick={openFilter}>
<TuneIcon/>
<span>Filter</span>
    </p>
    <p className="option" onClick={openSorting}>
<SortByAlphaIcon/>
<span>Sort</span>
    </p>
    <div className="filter-box" style={{bottom: filterOpen}}>
  <div className="headers">
    <p className="heads">FILTER</p>
    <span onClick={closeFilters}><CloseIcon/></span>
  </div>
  <div className="filter-body">
    <div className="left">
        <ul>
          <li onClick={()=>toggleMenu("color")} className={iseSelectedMenu("color")}>Color</li>
          <li onClick={()=>toggleMenu("size")} className={iseSelectedMenu("size")}>Size</li>
        </ul>
    </div>
    <div className="right">
      {
        menu==="size"?
        <div className="content">
<p>Size</p>
      <ul>
      {uniqueSizesArray.map((size, index) => (
        <li key={index} onClick={()=>toggleSize(size)} className={isSelectedSize(size)}>{size}</li>
      ))}
      </ul>
</div>
:
<div className="content">
<p>Color</p>
      <ul>
      {colorMenu.map((color, index) => (
        <li key={index} onClick={()=>toggleColor(color)} className={isSelectedColor(color)}>{color}</li>
      ))}
      </ul>
</div>


      }

    </div>
  </div>
  <div className="filter-foot">
    <p className="count">
    {productState?.pagination?.totalDocs} Products
    </p>
    <div className="right">
      <button onClick={clearFilter}>Clear Filters</button>
      <button onClick={applyFilter}>Done</button>
    </div>
  </div>
</div>
<div className="sort-box" style={{bottom:sortOpen}}>
<div className="headers">
    <p className="heads">SORT</p>
    <span onClick={closeSorting}><CloseIcon/></span>
  </div>
  <div className="sort-body">
    <p onClick={()=>toggleSort("-createdAt")} className={isSelectedSort("-createdAt")}>New to Old</p>
    <p onClick={()=>toggleSort("createdAt")} className={isSelectedSort("createdAt")}>Old to New</p>
    <p onClick={()=>toggleSort("price")} className={isSelectedSort("price")}>Price (Low to High)</p>
    <p onClick={()=>toggleSort("-price")} className={isSelectedSort("-price")}>Price (High to Low)</p>
    <p onClick={()=>toggleSort("title")} className={isSelectedSort("title")}>Title (A to Z)</p>
    <p onClick={()=>toggleSort("-title")} className={isSelectedSort("-title")}>Title (Z to A)</p>
  </div>
</div>
  </div>
  <div className="products-section">
    <div className="products-list">
        {
          productState?.products?.map((item,index)=>{
            return <Product info={item}/>
          })
        }
    </div>
    <div className="pagination">
    <Stack spacing={2}>
      <Pagination count={productState?.pagination?.totalPages} page={page} variant="outlined" shape="rounded" onChange={handlePageChange}/>
    </Stack>
    </div>
  </div>
</div>
        :
        <div className="loader">
          <div className="loading">
          <img src={loadingImg} alt="" />

          </div>
        </div>
      
      }
      
        
        
    </div>
  )
}

export default Products
