import React,{useContext,useEffect} from 'react'
import {HeaderContext} from '../../context/HeaderContext'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
const Contact = () => {
  const {headTextSet}=useContext(HeaderContext)
  useEffect(()=>{
headTextSet("Contact")
  },[])
  useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/contact`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
  return (
    <div className='contact-page'>
      <div className="banner">
        <h1>Contact</h1>
      </div>
      <div className="form">
        <div className="left">
          <p>Enquiry Form</p>
            <div className="name">
              <input type="text" placeholder='Name'/>
            </div>
            <div className="email">
              <input type="email" placeholder='Email'/>
            </div><div className="msg">
              <textarea name="" id="" placeholder='Message....'></textarea>
            </div>
            <div className="submit">
              <button>SEND</button>
            </div>
        </div>
        <div className="right">
          <div>
            <PhoneInTalkIcon className='icon'/>
            <p className='para'>Phone</p>
            <p className='infos'>+91 8920744425</p>
          </div>
          <div>
            <PhoneInTalkIcon className='icon'/>
            <p className='para'>Email</p>
            <p className='infos'>Lybrraa@gmail.com</p>
          </div><div>
            <PhoneInTalkIcon className='icon'/>
            <p className='para'>WhatsApp</p>
            <p className='infos'>+91 8920744425</p>
          </div><div>
            <PhoneInTalkIcon className='icon'/>
            <p className='para'>Address</p>
            <p className='infos'>Mansarovar Park, Shahdara, Delhi – 110032</p>
          </div>
        </div>
      </div>
      <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7000.527979382803!2d77.2932511879906!3d28.681748714987233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb9caebdf629%3A0xfba3859d1c079212!2sMansarovar%20Park%2C%20Shahdara%2C%20Delhi%2C%20110032!5e0!3m2!1sen!2sin!4v1725345443021!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Contact
