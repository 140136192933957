import React,{useEffect, useState,useContext} from 'react'
import './cart.css'
import { BsCart3 } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { HeaderContext } from '../../context/HeaderContext';
const Cart = () => {
  const {headTextSet,removeFromCart}=useContext(HeaderContext)
  useEffect(()=>{
headTextSet("Cart")
  },[])
const [cartItems, setCartItems] = useState([]);
    const [totalAmount,setTotalAmount]=useState(null)
    useEffect(() => {
        // Retrieve cart items from localStorage
        const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(cartFromStorage);
    }, []);

    const removeFromCartAndUpdate = (productIdToRemove, colorToRemove, sizeToRemove) => {
        const updatedCartItems = cartItems.filter(item => {
            return !(item.productId === productIdToRemove && item.color === colorToRemove && item.size === sizeToRemove);
        });
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
        toast.success("Removed");
        removeFromCart(cartItems)
    };
useEffect (()=> {
    let sum=0;
    for(let index=0; index < cartItems?.length; index++){
        sum =sum+(Number(cartItems[index]?.quantity) *cartItems[index]?.price)
        setTotalAmount(sum)
    }
},[cartItems])
const navigate=useNavigate()
useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/cart`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);

  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
    return (
        <div className='cart-page'>
            <h1 style={{textAlign:'center',margin:'20px 0',fontSize:'30px',display:'flex',alignItems:'center',justifyContent:'center'}}><BsCart3 style={{fontSize:'30px',marginRight:'10px'}}/> My Cart</h1>
            {
               cartItems?.length !== 0?
                <div className="cart-content margin-section">
                <div className="left-cart">
                    {
  cartItems?.map((item,index)=>{
    return(
                    <div className="cart-item" key={index}>
                        <div style={{display:'flex'}}>
                        <div className="cartItem-left">
                            <Link to={`/product/${item?.product?.handle}`}>
                            <div className="prdt-img">
                                <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" />
                            </div>
                            </Link>
                        </div>
                        <div className="cartItem-right">
                            <p className="prdts-name" style={{fontWeight:'bold'}}>{item?.product?.title}</p>
                            <div className="second-item">
                            <div className="size">
                                <p>Size:</p>
                                <p>{item?.size}</p>
                            </div>
                            <div className="size">
                                <p>Color:</p>
                                <p>{item?.color}</p>
                            </div>
                            <div className="quantity">
                                <p>Qty:</p>
                                <p>{item?.quantity}</p>
                            </div>
                            </div>
                            <p className="price">Rs. {item?.price}</p>
                        </div>
                        </div>
<div className="remove">
<p onClick={() => removeFromCartAndUpdate(item.productId, item.color, item.size)}>Remove</p>

</div>
                        
                    </div>

                    )
                })
              }
                                  <hr/>
                    
                    <div className="total-items">
                        <p className="quantit" style={{fontWeight:'bold'}}>{cartItems?.length} Item</p>
                        <p style={{fontWeight:'bold'}}>Rs. <span>{totalAmount}</span></p>
                    </div>
                </div>
                <div className="checkouts">
                <Link to="/checkout" >  <button className='checkout-btn'>CHECKOUT</button></Link>
                </div>
                
                    
                    
            </div>
            :
            <div className='margin-section no-data' style={{textAlign:'center'}}>
                <img src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg" alt="" />
                <p>NO DATA</p>
                <button className='checkout-btn' onClick={()=>navigate("/")}>CONTINUE SHOPPING</button>
            </div>
                    
                
            }
        </div>
    )
}

export default Cart
