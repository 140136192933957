import React,{useEffect,useContext} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import './collections.css'
import {getAllCollection} from '../../features/collection/collectionSlice'
import { Link } from 'react-router-dom'
import { HeaderContext } from '../../context/HeaderContext';

const Collections = () => {
    const {headTextSet}=useContext(HeaderContext)
    useEffect(()=>{
  headTextSet("Collections")
    },[])
  const dispatch=useDispatch()
  const collectionState = useSelector((state) => state?.collection?.collection);
  useEffect(()=>{
    dispatch(getAllCollection())
      },[])
      useEffect(() => {
        const canonicalUrl =`https://lybrraa.com/collections`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
  return (
    <div className='collections-page'>
      {
        collectionState && collectionState?.map((item,index)=>{
            return <Link to={`/collection/products/${item?.handle}`} key={index}>
            <div className='single-col'>
                <img src={item?.banner[0]?.url} alt="" />
                <p className="name">{item?.title}</p>
            </div>
            </Link>
        })
      }
    </div>
  )
}

export default Collections
