import React, {useState,useContext ,useEffect} from 'react';
import './orders.css';
import { useSelector, useDispatch } from 'react-redux';
import { getUserOrders } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import { HeaderContext } from '../../context/HeaderContext';

const Orders = () => {
    const {headTextSet}=useContext(HeaderContext)
useEffect(()=>{
headTextSet("Orders")
},[])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderState1 = useSelector(state => state?.auth?.getOrderedProduct);
  const error = useSelector((state) => state?.auth?.error);  // Get the error from state

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const trackOrder = () => {
    if (email?.length < 10) {
      toast.error("Please enter a valid email address.");
    } else {
      dispatch(getUserOrders({ email: email }));
      setShow(true);
    }
  };

  useEffect(() => {
    const canonicalUrl =`https://lybrraa.com/orders`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);

  return (
    <div className='orders-page'>
    <p className='orders-head'>Track Your Order</p>
    <div className="email-field">
      <input
        type="email"
        placeholder='Enter Your Email...'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={trackOrder}>Track</button>
    </div>
    {show && (
      <div>
        {orderState1 ? (
          <div className="all-orders">
            {orderState1?.orders?.map((item, index) => (
              <div className="order" key={index}>
                <div className="number">
                  <p>Order No.</p>
                  <p>{item?.orderNumber}</p>
                </div>
                <div className="items">
                  {item?.orderItems?.map((items, indexes) => (
                    <div className="item" key={indexes}>
                      <img src={items?.product?.images?.[0]?.url} alt="" />
                      <div className="right">
                        <p className="name">{items?.product?.title}</p>
                        <p className="color"><span>Color:</span>{items?.color}</p>
                        <p className="size"><span>Size:</span>{items?.size}</p>
                        <p className="qty"><span>Qty:</span>{items?.quantity}</p>
                        <p className="price"><span>Price:</span>Rs. {items?.price}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="info">
                  <ul>
                    <li>Name</li>
                    <li>Status</li>
                    <li>Date</li>
                    <li>Order Type</li>
                    <li>Subtotal</li>
                    <li>Shipping</li>
                    <li>Discount</li>
                    <li>Total</li>
                  </ul>
                  <ul>
                    <li>{item?.shippingInfo?.firstname}</li>
                    <li>{item?.orderStatus}</li>
                    <li>{new Date(item?.createdAt).toLocaleDateString()}</li>
                    <li>{item?.orderType}</li>
                    <li>Rs. {item?.totalPrice}</li>
                    <li>Rs. {item?.shippingCost}</li>
                    <li>Rs. {item?.discount}</li>
                    <li>Rs. {item?.finalAmount}</li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-data">
            <img src="https://i.pinimg.com/originals/6f/fd/64/6ffd64c5366898c59bbc91d9aec935c3.png" alt="" />
            <p>{error ? "Sorry We don't have any data." : "Loading..."}</p>
            <p>Kindly check your email again.</p>
            <button onClick={() => navigate("/home")}>SHOP NOW</button>
          </div>
        )}
      </div>
    )}
  </div>
  );
};

export default Orders;
