import React, { useEffect,useState,useContext } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { IoCloseOutline } from "react-icons/io5";
import toast from 'react-hot-toast'
import { PiHandHeartThin } from "react-icons/pi";
import './wishlist.css'
import { HeaderContext } from '../../context/HeaderContext';

const Wishlist = () => {
  const navigate=useNavigate()
const [wishlist,setWishlist]=useState([])
const {headTextSet,removeToWishlist}=useContext(HeaderContext)
useEffect(()=>{
headTextSet("Wishlist")
},[])
    useEffect(() => {
      const cartFromStorage = JSON.parse(localStorage.getItem('wishlist')) || [];
      setWishlist(cartFromStorage);
  }, []);

    const removeFromWishlist=(id)=>{
      const cardDetails = JSON.parse(localStorage.getItem('wishlist')) || [];
      const updatedWishlistDetails = cardDetails.filter(item => item.productId !== id);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlistDetails));
        setWishlist(updatedWishlistDetails)
      setTimeout(()=>{
       toast.success("Removed")
      },300)
      removeToWishlist(wishlist)
    }
    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };
  return (
    <div className='mywish'>
        <p className="wish-head">My Wishlist <PiHandHeartThin className='wish-icon'/></p>
        {
            wishlist.length===0?
            <div className='no-data'>
<img src="https://i.pinimg.com/originals/f6/e4/64/f6e464230662e7fa4c6a4afb92631aed.png" alt="" />
<button onClick={(e)=>navigate("/")}>ADD SOMETHING</button>
              </div>
              :
              <div className="products-listing">
              <div className="product-list ">
      
      {
        wishlist?.map((item,index)=>{
          return(
            <div className='product-comp prdt'>
      <div className="top">
      <Link to={`/product/${item?.handle}`}>
        <img src={modifyCloudinaryUrl(item?.images[0]?.url)} alt="" />
        </Link>

<p className="wish-icon" onClick={(e)=>{removeFromWishlist(item?._id)}}><IoCloseOutline className="cart-icon"/></p>

                
      </div>
      <div className="bottom">
<p className="name">{item?.title}</p>
<p className="price">Rs. {item?.price}</p>
      </div>
    </div>
          )
        })
      }
               
      
              </div>
            </div>
          }
           
    </div>
  )
}

export default Wishlist
