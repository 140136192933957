import axios from "axios";
import {base_url, config} from '../../utils/axiosConfig'


const getPages = async () => {
  const response = await axios.get(`${base_url}page/`);

  return response.data;
};

const getPage = async (id) => {
  const response = await axios.get(`${base_url}page/${id}`);

  return response.data;
};

const pageService = {
  getPages,
  getPage
};

export default pageService;
